<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('Tekliflerim') }}</b-card-title>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(onumber)="data">
        <b-link
          :to="$route.path + '/view/' + data.item.id"
          class="text-body"
          :disabled="data.item.id_offer_statuses !== '2' || moment(data.item.vdate).diff(moment(),'days') < '-1'"
        >
          {{ data.item.onumber }}
        </b-link>
      </template>
      <template #cell(title)="data">
        <b-link
          :to="$route.path + '/view/' + data.item.id"
          class="text-body"
          :disabled="data.item.id_offer_statuses !== '2' || moment(data.item.vdate).diff(moment(),'days') < '-1'"
        >
          {{ data.item.title }}
          <div class="font-small-2 text-muted">
            {{ data.item.username }} {{ $t('tarafından gönderildi') }}.
          </div>
        </b-link>
      </template>
      <template #cell(odate)="data">
        <div>
          <template v-if="moment(data.item.vdate).diff(moment(),'days') >= '-1'">
            <div class="font-weight-bold text-primary">
              {{ moment(data.item.odate).format('DD.MM.YYYY') }}
            </div>
            <div class="font-small-2 text-muted">
              {{ moment(data.item.vdate).format('DD.MM.YYYY') }} {{ $t('tarihine kadar geçerli') }}
            </div>
            <div class="text-warning font-small-2">
              {{ moment(data.item.vdate).diff(moment(),'days') > '-1'? moment(data.item.vdate).diff(moment(),'days') + ' ' + $t('gün kaldı') : $t('Son Gün') }}
            </div>
          </template>
          <template v-else>
            <div class="font-small-2 text-danger">
              <FeatherIcon icon="XCircleIcon" />
              <div>
                Teklif süresi doldu.
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="$route.path + '/view/' + data.item.id"
          :disabled="moment(data.item.vdate).diff(moment(),'days') < '-1'"
        >
          <FeatherIcon icon="FileTextIcon" />
          {{ $t('Görüntüle') }}
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardHeader, BCardTitle, BButton, BLink,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BButton,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'onumber',
          label: this.$i18n.t('Teklif No'),
          thClass: 'width-50 text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'title',
          label: this.$i18n.t('Teklif Adı'),
        },
        {
          key: 'odate',
          label: this.$i18n.t('Teklif Tarihi'),
          thClass: 'width-50 text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-50 text-nowrap',
          tdClass: 'text-nowrap text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['offers/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('offers/getDataList')
    },
  },
}
</script>
